import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesConst = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Algunas de nuestras obras',
    paragraph: 'Ejemplos del antes y el después en obras realizadas por nosotros tanto para particulares como para empresas.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-from-top',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item">
                  <Image
                    src={require('./../../assets/images/before-01.jpg')}
                    alt="Features split 01"
                    height={396} />
                </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/after-01.jpg')}
                  alt="Features split 01"
                  height={396} />
              </div>
            </div>

            <div className="split-item">
            <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/before-02.jpg')}
                  alt="Antes y después"
                  height={396} />
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/after-02.jpg')}
                  alt="Antes y después"
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-from-top',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item">
                  <Image
                    src={require('./../../assets/images/before-03.jpg')}
                    alt="Diseño funcional"
                    height={396} />
                </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/after-03.jpg')}
                  alt="Diseño funcional"
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-from-top',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item">
                  <Image
                    src={require('./../../assets/images/before-04.jpg')}
                    alt="Diseño funcional"
                    height={396} />
                </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/after-04.jpg')}
                  alt="Diseño funcional"
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-from-top',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item">
                  <Image
                    src={require('./../../assets/images/before-05.jpg')}
                    alt="Diseño funcional"
                    height={396} />
                </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/after-05.jpg')}
                  alt="Diseño funcional"
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-from-top',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item">
                  <Image
                    src={require('./../../assets/images/before-06.jpg')}
                    alt="Diseño funcional"
                    height={396} />
                </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/after-06.jpg')}
                  alt="Diseño funcional"
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-from-top',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item">
                  <Image
                    src={require('./../../assets/images/before-07.jpg')}
                    alt="Diseño funcional"
                    height={396} />
                </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/after-07.jpg')}
                  alt="Diseño funcional"
                  height={396} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesConst.propTypes = propTypes;
FeaturesConst.defaultProps = defaultProps;

export default FeaturesConst;